<template>
  <div>
    <app-echart-summary-bar :option-data="getData" />
  </div>
</template>

<script>
import { BCard } from "bootstrap-vue";
import AppEchartSummaryBar from "@/@core/components/charts/echart/AppEchartSummaryBar.vue";

export default {
  components: {
    BCard,
    AppEchartSummaryBar,
  },
  props: {
    data: {
      required: true,
    },
  },
  computed: {
    getData: function () {
      let option = this.option;
      option.xAxis[0].data = this.data.xAxis;
      option.series[0].data = this.data.above_is_equal_to_three;
      option.series[1].data = this.data.under_three;
      option.series[2].data = this.data.number_of_lecturers;
      return option;
    },
  },
  data() {
    return {
      option: {
        xAxis: [
          {
            show: true,
            type: "category",
            axisLabel: {
              fontSize: 10,
              formatter: function (params) {
                var newParamsName = ""; // The final concatenated string
                var paramsNameNumber = params.length; // Number of actual labels
                var provideNumber = 13; // Number of words per line
                var rowNumber = Math.ceil(paramsNameNumber / provideNumber); // If you change lines, you need to show a few lines and take them up.
                /**
                 * Determine whether the number of tags is greater than the specified number, and if it is greater than the number of new lines, if it is not greater than, that is, equal to or less than, return to the original tag.
                 */
                // The condition is equal to rowNumber > 1
                if (paramsNameNumber > provideNumber) {
                  /** Loop each row, p represents the row */
                  for (var p = 0; p < rowNumber; p++) {
                    var tempStr = ""; // A string representing each intercept
                    var start = p * provideNumber; // Starting interception position
                    var end = start + provideNumber; // End Interception Position
                    // The index value of the last row is specially handled here
                    if (p == rowNumber - 1) {
                      // No change of line for the last time
                      tempStr = params.substring(start, paramsNameNumber);
                    } else {
                      // Each concatenation of strings and line breaks
                      tempStr = params.substring(start, end) + "\n";
                    }
                    newParamsName += tempStr; // The final string
                  }
                } else {
                  // Assign the value of the old tag to the new tag
                  newParamsName = params;
                }
                //Returns the final string
                return newParamsName;
              },
            },
            axisLine: {
              show: false, // Hide full Line
            },
            splitLine: { show: false },
            data: this.data.xAxis,
          },
        ],
        yAxis: [
          {
            axisLine: {
              show: false, // Hide full Line
            },
            type: "value",
            splitLine: { show: true },
          },
        ],
        grid: {
          left: "40px",
          right: "30px",
          bottom: "100px",
        },
        series: [
          {
            name: "Nilai => 3",
            type: "bar",
            data: this.data.xAxis,
            barMaxWidth: "20%",
            barMinWidth: "10px",
            itemStyle: {
              barBorderRadius: [10, 10, 0, 0],
              color: ["#28C76F"],
            },
          },
          {
            name: "Nilai < 3",
            type: "bar",
            data: [],
            barMaxWidth: "20%",
            barMinWidth: "10px",
            itemStyle: {
              barBorderRadius: [10, 10, 0, 0],
              color: ["#EA5455"],
            },
          },
          {
            name: "Dosen",
            type: "bar",
            data: [],
            barMaxWidth: "20%",
            barMinWidth: "10px",
            itemStyle: {
              barBorderRadius: [10, 10, 0, 0],
              color: ["#7367F0"],
            },
          },
        ],
      },
    };
  },
};
</script>
