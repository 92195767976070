<template>
  <b-card>
    <e-charts
      ref="line"
      autoresize
      :options="line"
      theme="theme-color"
      auto-resize
    />
  </b-card>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BRow,
  BCol,
} from "bootstrap-vue";
import ECharts from "vue-echarts";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/chart/line";
import theme from "@core/components/charts/echart/theme.json";

ECharts.registerTheme("theme-color", theme);

export default {
  components: {
    ECharts,
    BCard,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdownItem,
    BDropdown,
    BRow,
    BCol,
  },
  props: {
    api: {
      type: String,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      uri: this.$props.api,
      accumulation: {},
      line: {
        // Make gradient line here
        visualMap: [
          {
            show: true,
            type: "continuous",
            min: 0,
            max: 400,
          },
        ],
        grid: {
          width: "92%",
          left: "24px",
          top: "10px",
          show: false,
        },
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          showGrid: false,
          splitLine: {
            show: false,
          },
          axisLine: {
            show: false, // Hide full Line
          },
          boundaryGap: false,
          data: [],
        },
        yAxis: {
          axisLine: {
            show: false, // Hide full Line
          },
          type: "value",
          splitLine: { show: true },
        },
        series: {
          type: "line",
          showSymbol: true,
          smooth: true,
          data: [],
          label: {
            show: true,
            position: "bottom",
            textStyle: {
              fontSize: 14,
            },
          },
        },
      },
    };
  },
  async mounted() {
    const _ = this;
    const data = await _.getData();
    _.line.xAxis.data = _.mapData(data, "semester");
    _.line.series.data = _.mapData(data, "jumlah_mahasiswa");
    _.accumulation = data.accumulation;
  },
  methods: {
    async getData() {
      const _ = this;
      let data;
      await _.$axios.get(_.uri).then((res) => {
        data = res.data.data;
      });
      return data;
    },
    mapData(data, type) {
      let consume = [];
      data.activeStudent.forEach((element) => {
        if (type === "semester") {
          consume = [...consume, element.semester];
        } else if (type === "jumlah_mahasiswa") {
          consume = [...consume, element.jumlah_mahasiswa];
        }
      });
      return consume;
    },
  },
};
</script>

<style>
.echarts {
  width: 100% !important;
}
</style>
