<template>
  <b-card>
    <e-charts
      ref="pie"
      autoresize
      :options="pie"
      theme="theme-color"
      auto-resize
    />

    <div class="pt-25">
      <div
        v-for="(data, index) in pie.series.data"
        :key="index"
        class="d-flex justify-content-between"
        :class="
          index === Object.keys(pie.series.data).length - 1 ? '' : 'mb-1'
        "
      >
        <div class="series-info">
          <feather-icon
            icon="CircleIcon"
            size="16"
            class="mr-50"
            :class="data.color"
          />
          <span class="font-weight-bolder">{{ data.name }}</span>
        </div>
        <span>{{ data.value }}</span>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BRow,
  BCol,
} from "bootstrap-vue";
import ECharts from "vue-echarts";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/chart/pie";
import theme from "@core/components/charts/echart/theme.json";

ECharts.registerTheme("theme-color", theme);

export default {
  components: {
    ECharts,
    BCard,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdownItem,
    BDropdown,
    BRow,
    BCol,
  },
  props: {
    api: {
      type: String,
      required: true,
      default: null,
    },
    legend: {
      type: Array,
    },
  },
  data() {
    return {
      uri: this.$props.api,
      accumulation: {},
      pie: {
        title: {
          text: "Referer of a Website",
          subtext: "Fake Data",
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        series: {
          type: "pie",
          radius: "75%",
          label: {
            show: false,
          },
          data: {},
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
        color: ["#28C76F", "#FF9F43", "#EA5455"],
      },
    };
  },
  async mounted() {
    const _ = this;
    const data = await _.getData();
    const mapped_data = _.mapData(data.accumulation);
    _.pie.series.data = mapped_data;
  },
  methods: {
    async getData() {
      const _ = this;
      let data;
      await _.$axios.get(_.uri).then((res) => {
        data = res.data.data;
      });
      return data;
    },
    mapData(data) {
      let result = [];
      result = [...result, { name: "> 3.5", value: data.top, color: "text-success" }];
      result = [...result, { name: ">= 2 - 3.5", value: data.middle, color: "text-warning" }];
      result = [...result, { name: "< 2", value: data.low, color: "text-danger" }];
      return result;
    },
  },
};
</script>

<style>
.echarts {
  width: 100% !important;
}
</style>
