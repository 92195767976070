<template>
  <div>
    <b-card>
      <b-row>
        <b-col>
          <b-form-group>
            <v-select v-model="filter.angkatan" :options="years" :reduce="(item) => item.value"
              placeholder="Tahun Ajaran" label="name" @input="handleChange" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group>
            <v-select v-model="filter.semester" :options="semesters" :reduce="(item) => item.value"
              placeholder="Semester" label="name" @input="handleChange" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group>
            <v-select v-model="filter.block" :options="blocks" :reduce="(item) => item.value" placeholder="Blok"
              label="name" @input="handleChange" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group>
            <v-select v-model="filter.category" :options="categories" :reduce="(item) => item.value" placeholder="Kategori"
              label="name" @input="handleChange" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col>
        <b-card title="Grafik Hasil Kuesioner Dosen">
          <summary-bar-chart :data="graphic" :key="bar_key" />
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <c-table-lecturer-session-summary :title="title" :items="getItems" :columns="columns" :totalRows="totalRows"
          :perPage="perPage" :currentPage="currentPage" :api="api" :filterCourse="true"
          @changeCurrentPage="changeCurrentPage" @changePerPage="changePerPage"></c-table-lecturer-session-summary>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BRow,
  BCol,
} from "bootstrap-vue";
import LineChart from "@/components/LineChart.vue";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import table from "@/libs/table";
import PieChart from "@/components/PieChart.vue";
import DoughnutChart from "@/components/DoughnutChart.vue";
import CTableLecturerSessionSummary from "@/components/CTableLecturerSessionSummary.vue";
import vSelect from "vue-select";
import SummaryBarChart from "@/components/SummaryBarChart.vue";

export default {
  components: {
    BCard,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdownItem,
    BDropdown,
    BRow,
    BCol,
    LineChart,
    PieChart,
    DoughnutChart,
    vSelect,
    SummaryBarChart,
    CTableLecturerSessionSummary,
  },
  data() {
    const permission = this.$store.getters["app/ACTIONS"];
    return {
      bar_key: 0,
      table_key: 0,
      title: "Kuesioner",
      api: `questionnaire-session/list-questionnaire-session`,
      graphic_api: `questionnaire-session-answer/chart-questionnaire-session`,
      graphic: {},
      dir: false,
      items: [],
      filter: {
        angkatan: null,
        semester: null,
        block: null,
        category: null,
      },
      totalRows: 0,
      perPage: 10,
      limit: 0,
      orderBy: "createdAt",
      sortBy: "desc",
      currentPage: 1,
      search: "",
      years: [],
      semesters: [
        {
          name: "Semua",
          value: null,
        },
        {
          name: "Ganjil",
          value: "ganjil",
        },
        {
          name: "Genap",
          value: "genap",
        },
      ],
      blocks: [],
      categories:[
        {
          name: 'Kuliah',
          value: 'kuliah'
        },
        {
          name: 'Tutorial/Diskel',
          value: 'tutorial/diskel'
        },
        {
          name: 'CSL',
          value: 'csl'
        },
        {
          name: 'Praktikum',
          value: 'praktikum'
        },
      ],
      columns: [
        { key: "no", label: "#", sortable: false },
        { key: "blockkeyname", label: "Nama Blok", sortable: true },
        { key: "year", label: "Tahun Ajaran", sortable: true },
        { key: "semester", label: "Semester", sortable: true },
        {
          key: "action",
          label: "aksi",
          class: "text-center",
          actions: { show: Boolean(permission.show) }, // Boolean(permission.show)
        },
      ],
    };
  },
  async mounted() {
    const _ = this;
    _.graphic = await this.getData();
    _.years = await this.getYears();
    _.blocks = await this.getBlocks();
  },
  methods: {
    async getData() {
      const _ = this;
      let data;
      await _.$axios.get(_.graphic_api, { params: this.filter }).then((res) => {
        data = res.data.data;
      });
      return data;
    },
    getItems(ctx, callback) {
      table.getItems(ctx, callback, this);
    },
    changeCurrentPage(page) {
      table.changeCurrentPage(this, page);
    },
    changePerPage(perPage) {
      table.changePerPage(this, perPage);
    },
    async getYears() {
      const _ = this;
      let data;
      let result = [];
      await _.$axios.get("misc/years").then((res) => {
        data = res.data;
      });
      result = [...result, { name: "Semua", value: null }];
      data.forEach((element) => {
        result = [
          ...result,
          {
            name: element.year,
            value: element.year,
          },
        ];
      });
      return result;
    },
    async getBlocks(){
      const _ = this;
      let data;
      let result = [];
      await _.$axios.get("misc/blocks").then((res) => {
        data = res.data
      })

      result = [...result, { name: "Semua", value: null }];
      data.forEach((element) => {
        result = [
          ...result,
          {
            name: element.name,
            value: element.id,
          },
        ];
      });
      
      return result;
    },
    async handleChange() {
      this.graphic = await this.getData();
      this.bar_key += 1;
    },
  },
};
</script>
